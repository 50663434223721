import detect from 'feature-detect-es6';

detect.fetch = () => {
  try {
    eval('fetch'); /* eslint-disable-line no-eval */
    return true;
  } catch (err) {
    return false;
  }
};

detect.closest = () => {
  try {
    eval('Element.prototype.closest'); /* eslint-disable-line no-eval */
    return true;
  } catch (err) {
    return false;
  }
};

const polyfillScript = document.getElementById('polyfill-script');

if (!detect.all(
  'arrowFunction',
  'class',
  'closest',
  'collections',
  'const',
  'defaultParamValues',
  'destructuring',
  'fetch',
  'generators',
  'let',
  'newArrayFeatures',
  'promises',
  'spread',
  'templateStrings',
)) {
  const babelPolyfillScript = document.createElement('script');
  babelPolyfillScript.src = '/assets/dist/js/babel-polyfill.min.js';
  document.body.insertBefore(babelPolyfillScript, polyfillScript);

  const fetchScript = document.createElement('script');
  fetchScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/fetch/2.0.3/fetch.min.js';
  document.body.insertBefore(fetchScript, polyfillScript);

  const closestScript = document.createElement('script');
  closestScript.src = 'https://unpkg.com/element-closest@3.0.2/browser.js';
  document.body.insertBefore(closestScript, polyfillScript);
}

// Event constructor
if (typeof window.CustomEvent !== 'function') {
  // console.log('polyfill.js, Add Custom Event Support');
  const CustomEvent = (event, eventParams) => { /* eslint-disable-line no-unused-vars */
    const params = eventParams || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
  window.Event = CustomEvent;
}

// object-fit fallback
if ('objectFit' in document.documentElement.style === false) {
  const imgs = document.querySelectorAll('.object-fit');
  for (let i = 0; i < imgs.length; i++) { /* eslint-disable-line no-plusplus */
    const wrapper = imgs[i];
    const img = wrapper.getElementsByTagName('img')[0];
    if (img) {
      let imgUrl = img.src;
      if (imgUrl === null || imgUrl === '') {
        imgUrl = img.getAttribute('data-src');
      }
      if (imgUrl === null || imgUrl === '') {
        imgUrl = img.getAttribute('data-src-ie11');
      }
      if (imgUrl) {
        wrapper.setAttribute('style', `background-image:url(${encodeURI(imgUrl)});`);
        wrapper.classList.add('compat-object-fit');
      }
    }
  }
}
